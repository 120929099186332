.layout {
  padding: 20px 30px;
  min-height: 100vh;
}

.themeOption {
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
